.header-wrapper{
  position: relative;
  background: linear-gradient(to right, #3a3633 93%, #2a2725 100%);
  -webkit-box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
  height: 45px;
  border: 1px solid rgba(34,36,38,.15);
  z-index: 12;
  border-width: 1px 0;
  border-bottom: 1px solid #525151;

  .main-header{
    top: 30%;
    position: absolute;
    border-radius: 0;
    font-size: 12px !important;
    height: 39 px;
    color: #fff;
    font-family: "Open Sans",Arial,Helvetica,Sans-Serif;
    margin-top: -7px !important;
    margin-bottom: 10px !important;


    .username-dropdown{
      margin-right: 20px;
      margin-left: 10px !important;
      line-height: normal;
      font-size: 14px;
      color: #c0bbb7 !important;
      font-weight: 400;
      text-decoration: none!important;
      position: relative;
      background-color: transparent !important;

    }

     .transition {
              	-webkit-animation-name: flipInX;
              	-moz-animation-name: flipInX;
              	-o-animation-name: flipInX;
              	animation-name: flipInX;
              	-webkit-animation-duration: .4s;
              	-moz-animation-duration: .4s;
              	-o-animation-duration: .4s;
              	animation-duration: .4s;
              	-webkit-animation-fill-mode: both;
              	-moz-animation-fill-mode: both;
              	-o-animation-fill-mode: both;
              	animation-fill-mode: both;
              	border-radius: unset;
              }

     .ui.dropdown .menu>.item {
        font-size: 13px !important;
     }

    .username-dropdown:focus {
        color: #c9c9c9 !important;
    }

    .username-dropdown:hover {
        color: #fff !important;
        text-decoration: none
    }
    .mru-list-dropdown{
      width: 150px !important;
      line-height: normal;
      font-size: 14px;
      color: #c0bbb7;
      font-weight: 400;
      text-decoration: none!important;
      position: relative;
    }

    .mru-list-dropdown:focus {
      color: #c9c9c9 !important;
    }

    .mru-list-dropdown:hover {
      color: #fff !important;
      text-decoration: none
    }

    .online{
       margin-left: 10px;
       margin-top: unset;
       border-radius: unset !important;
    }

    .signout-button {
      top: 5px;
      position: fixed;
      right: 0px;
      background: transparent;
      color: #c0bbb7 !important;
    }

    .signout-button:focus {
      color: #c9c9c9 !important;
      background: transparent;
  }

    .signout-button:hover {
      color: #fff !important;
      background: transparent;
      text-decoration: none
  }

    .logo{
      display: none;
    }
  }
}


@-webkit-keyframes flipInX {
	0% {
		-webkit-transform: perspective(400px) rotateX(90deg);
		opacity: 0
	}
	40% {
		-webkit-transform: perspective(400px) rotateX(-10deg)
	}
	70% {
		-webkit-transform: perspective(400px) rotateX(10deg)
	}
	100% {
		-webkit-transform: perspective(400px) rotateX(0deg);
		opacity: 1
	}
}

@-moz-keyframes flipInX {
	0% {
		-moz-transform: perspective(400px) rotateX(90deg);
		opacity: 0
	}
	40% {
		-moz-transform: perspective(400px) rotateX(-10deg)
	}
	70% {
		-moz-transform: perspective(400px) rotateX(10deg)
	}
	100% {
		-moz-transform: perspective(400px) rotateX(0deg);
		opacity: 1
	}
}

@-o-keyframes flipInX {
	0% {
		-o-transform: perspective(400px) rotateX(90deg);
		opacity: 0
	}
	40% {
		-o-transform: perspective(400px) rotateX(-10deg)
	}
	70% {
		-o-transform: perspective(400px) rotateX(10deg)
	}
	100% {
		-o-transform: perspective(400px) rotateX(0deg);
		opacity: 1
	}
}

@keyframes flipInX {
	0% {
		transform: perspective(400px) rotateX(90deg);
		opacity: 0
	}
	40% {
		transform: perspective(400px) rotateX(-10deg)
	}
	70% {
		transform: perspective(400px) rotateX(10deg)
	}
	100% {
		transform: perspective(400px) rotateX(0deg);
		opacity: 1
	}
}

.animated.flipInX {
	-webkit-backface-visibility: visible!important;
	-webkit-animation-name: flipInX;
	-moz-backface-visibility: visible!important;
	-moz-animation-name: flipInX;
	-o-backface-visibility: visible!important;
	-o-animation-name: flipInX;
	backface-visibility: visible!important;
	animation-name: flipInX
}



@media (min-width: 350px) and (max-width: 767px) {
  .header-wrapper{
    .main-header{
      width: 100%;
      margin-top: -.14285714em !important;

      .username-dropdown{
        margin-left: 25px;
      }
      .mru-list-dropdown{
        display: none;
      }

      .logo{
        display: block;
        float: right;
        margin-right: 5px;
      }

      .online{
         display: none;
         border-radius: unset !important;
      }
    }
  }
}

@media (max-width: 349px) {
  .header-wrapper{
    .main-header{
      width: 100%;
      margin-top: -.14285714em !important;

      .username-dropdown{
        margin-left: 25px;
      }
      .mru-list-dropdown{
        display: none;
      }

      .logo{
        display: block;
        float: right;
        margin-right: 5px;
      }

      .online{
          display: none;
          border-radius: unset !important;
      }
    }
  }
}