.tooltip {
  padding: 3px;
  border: 2px solid;
  border-radius: 4px;
  background-color: #eee;
  opacity: 0.6;
  justify-content: center;
  align-items: center;
}

.barChartContainer {
  display: inline-block;
}

.barChartLegend {
  font-size: 14px;
  bottom: 60%;
  right: 8%;
  position: fixed;
  display: flex;
}


@media (min-width: 320px) and (max-width: 800px) {
  .barChartContainer {
    position: unset;
    zoom: 70%;
  }
}