.date-search-queue {
    clear: right;
}

.filterResults {
    float: right;
    margin-bottom: 20px;
}

.queue-search {
    float: unset !important;
}

.client-search {
    float: right !important; 
}

.groupResults {
    float: right;
    margin-right: 30px;
    margin-bottom: 20px;
}

.groupby-client {
    margin-right: 48px !important;
}

.processedQueueTable {
    padding-bottom: 20px;
}

.state-dropdown {
  //  min-width: 200px !important;
    margin-left: 10px !important;
}

.clientId-dropdown {
  //  min-width: 200px !important;
    margin-left: 10px !important;
}


.dateRange-dropdown {
    padding-left: 10px !important;
    width: 82%;
}

.dateRange-dropdown> div {
    color: rgba(0,0,0,.87);
    box-shadow: none;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    transition: box-shadow .1s ease,width .1s ease;
    min-height: 2.71428571em;
    background: #fff;
    width: calc(100% + 12px) !important;
}

.dateRange-dropdown> div >div{
    height: unset !important;
    min-width: auto !important;
}

.popup-calendar {
    box-shadow: none;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
}

.filter-panel {
    border: none !important;
    box-shadow: none !important;
    border-radius: unset !important;
    margin: 0px !important;
}


.ui.item.menu .item {
    padding: 10px !important;
}

.filter-menu-item-1 {
    width: 30% !important;
}

.filter-menu-item-2 {
    width: 25% !important;
}

.filter-menu-item-3 {
    width: 20% !important;
}

.filter-menu-item-5 {
    width: 13% !important;
    margin-left: auto;
    margin-right: 0;
}

.filter-menu-item-6 {
    width: 10% !important;
    margin-left: auto;
    margin-right: 0;
}
.hidden {
        height:0px;
           -webkit-transition:height, 0.5s linear;
        -moz-transition: height, 0.5s linear;
        -ms-transition: height, 0.5s linear;
        -o-transition: height, 0.5s linear;
        transition: height, 0.5s linear;
       // opacity: 0;
    }


.open {
    height:300px;
    -webkit-transition:height, 0.5s linear;
   -moz-transition: height, 0.5s linear;
   -ms-transition: height, 0.5s linear;
   -o-transition: height, 0.5s linear;
   transition: height, 0.5s linear;
  // opacity: 1;
   float: left;
   width: 100%;
   }

   .menu-panel {
    position: absolute;
    border-radius: .28571429rem;
    z-index: 2;
    border: 1px solid rgba(34,36,38,.15);
    box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    border-radius: .28571429rem;
    width: 100%;
    background-color: #ffffff;
    margin-bottom: 20px;
   
   }


   .queue-panel {
    position: absolute !important;
    width: 100% !important;
    z-index: 1 !important;
    margin-top: 10% !important;
   }

   .queue-container {
       position: relative !important;
   }

   .email-inputs {
    float: left;
    clear: left;
    width: 100%;
    padding-bottom: 10px;
   }

   .email-content {
    padding-left: 20px;
    padding-right: 20px;
   }

   .new-line {
        white-space: pre-line;
    }

    .email-heading {
        float: left;
    }

    .send-email {
        float: right;
        margin-bottom: 10px !important;
    }

    .email-files {
       float: right;
       padding: 5px;
    }

    .email-files > div {
        padding-left: 10px;
    }


@media (min-width: 320px) and (max-width: 767px) {
    .queue-panel {
        top: 300px;
    }

    .email-files {
        float: right;
        clear: both;
     }

    .dateRange-dropdown>div>div {
        zoom: 70%;
    }

}  

@media (min-width: 768px) and (max-width: 800px) {
    .filter-panel {
        zoom: 50%;
    }
}