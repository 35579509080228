.sheet{
    margin-bottom: 20px !important;
    .table-input>input{
      width: 100%;
      border-radius: unset;
    }



    .inactive-cells{
      &:hover{
        cursor: pointer;
      }
    }

    .active-cells{
      &:hover{
        background: #eceff1;
      }
    }
    .inactive-cells{
      font-weight: bold;
    }
  
    .table-header{
      .header-buttons{
        float: right;
        button{
          width: 30px;
          height: 32px;
          padding: 0;
          position: relative;
          background-color: transparent;
          color: #fff;
          i{
            width: 100% ;
            height: 100% !important;
            line-height: 2.5;
          }
          .minimize {
            margin-top: -5px !important;
          }
          .expand {
            margin-top: -2px !important;
          }
        }
      }
  
      .header-cell{
          background: #404040 !important;
          color: #fff !important;
          padding: 0px;
  
      }
  
  
      .widget-icon {
          display: block;
          width: 30px;
          height: 32px;
          position: relative;
          float: left;
          font-size: 111%;
          line-height: 32px;
          text-align: center;
          margin-right: -10px
      }
  
      .dropdown-wrapper{
        display: none;
      }
      .header-title{
        display: inline-block;
        position: absolute;
        text-align: center;
        width: 90%;
        h2{
          font-family: "Open Sans", Arial, Helvetica, sans-serif;
          font-size: 14px;
          position: relative;
          line-height: 35px;
          font-weight: 400;
          float: left;
          margin-left: 10px !important;
        }
      }
    }
  
  
      .footer-wrapper {
          padding-bottom: 0px !important;
      }
  
      .ui.pagination.menu {
          min-height: 25px;
          height: 25px;
          margin-bottom: 7px !important;
          width: fit-content;
  
          .item{
            min-width: 2px;
            text-align: center;
            font-size: x-small;
          }
  
          .icon.item i.icon {
              font-size: 10px;
          }
      }
  
      .ui.menu .item {
        padding: 11px 6px;
      }
  
     .transition {
           -webkit-animation-name: flipInX;
           -moz-animation-name: flipInX;
           -o-animation-name: flipInX;
           animation-name: flipInX;
           -webkit-animation-duration: .4s;
           -moz-animation-duration: .4s;
           -o-animation-duration: .4s;
            animation-duration: .4s;
            -webkit-animation-fill-mode: both;
            -moz-animation-fill-mode: both;
            -o-animation-fill-mode: both;
            animation-fill-mode: both;
            border-radius: unset !important;
     }
  
  
    .table-inputs-block {
      background-image: -webkit-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
      background-color: #eee;
      line-height: 1.42857143;
      font-size: 12px;
    }
  
    .inactive-cells {
        background-image: -webkit-linear-gradient(top, #f2f2f2 0, #fafafa 100%);
        background-color: #eee;
        line-height: 1.42857143;
        font-size: 12px;
     }
  
  
     .even {
         background-color: #f9f9f9;
     }
  
     .active-cells {
          line-height: 1.42857143;
          font-size: 12px;
     }
  
     .footer-wrapper {
          background: #fafafa;
          font-size: 11px;
          padding: 5px 10px;
          overflow: hidden;
          border-top: 1px solid #ccc;
          box-shadow: inset 0 1px #fff;
     }
  
  
    .footer-title{
      line-height: 2;
      font-weight: 700;
      opacity: 0.6;
      padding-top: 9px;
      font-size: 12px;
      font-style: italic;
    }
  }
  .modal-content{
    padding: 40px 15px 15px 15px;
    height: 200px;
    position: relative;
    input{
      height: 30px;
      margin-bottom: 15px;
    }
    .close-button{
      position: absolute!important;
      bottom: 15px;
      right: 15px;
    }
    div{
      display: grid;
      justify-content: center;
      span{
        margin-bottom: 15px;
      }
    }
    .date-content{
      display: grid;
      grid-template-columns: 50px auto;
      align-items: center;
    }
  }
  
  
  
  @-webkit-keyframes flipInX {
      0% {
          -webkit-transform: perspective(400px) rotateX(90deg);
          opacity: 0
      }
      40% {
          -webkit-transform: perspective(400px) rotateX(-10deg)
      }
      70% {
          -webkit-transform: perspective(400px) rotateX(10deg)
      }
      100% {
          -webkit-transform: perspective(400px) rotateX(0deg);
          opacity: 1
      }
  }
  
  @-moz-keyframes flipInX {
      0% {
          -moz-transform: perspective(400px) rotateX(90deg);
          opacity: 0
      }
      40% {
          -moz-transform: perspective(400px) rotateX(-10deg)
      }
      70% {
          -moz-transform: perspective(400px) rotateX(10deg)
      }
      100% {
          -moz-transform: perspective(400px) rotateX(0deg);
          opacity: 1
      }
  }
  
  @-o-keyframes flipInX {
      0% {
          -o-transform: perspective(400px) rotateX(90deg);
          opacity: 0
      }
      40% {
          -o-transform: perspective(400px) rotateX(-10deg)
      }
      70% {
          -o-transform: perspective(400px) rotateX(10deg)
      }
      100% {
          -o-transform: perspective(400px) rotateX(0deg);
          opacity: 1
      }
  }
  
  @keyframes flipInX {
      0% {
          transform: perspective(400px) rotateX(90deg);
          opacity: 0
      }
      40% {
          transform: perspective(400px) rotateX(-10deg)
      }
      70% {
          transform: perspective(400px) rotateX(10deg)
      }
      100% {
          transform: perspective(400px) rotateX(0deg);
          opacity: 1
      }
  }
  
  .animated.flipInX {
      -webkit-backface-visibility: visible!important;
      -webkit-animation-name: flipInX;
      -moz-backface-visibility: visible!important;
      -moz-animation-name: flipInX;
      -o-backface-visibility: visible!important;
      -o-animation-name: flipInX;
      backface-visibility: visible!important;
      animation-name: flipInX
  }
  
  @media (min-width: 480px) and (max-width: 767px) {
    .sheet{
      width: 380px!important;
      .table-inputs-block{
        display: none;
      }
      .scroll-block{
        display: block;
        max-width: 380px!important;
        width: 100%;
        overflow: hidden;
        overflow-x: auto;
      }
      .active-cells{
        td{
          min-width: 150px!important;
        }
      }
      .table-header {
        .header-cell {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          .header-buttons {
            display: grid;
            grid-template-columns: repeat(1, 40px);
            justify-content: right;
            align-items: center;
            margin-top: 5px;
            button{
              width: 76%;
              height: 90%;
              position: relative;
              i{
                position: absolute;
                top: 1px;
                left: 0px;
                font-size: 11px;
              }
            }
          }
          .dropdown-wrapper {
            display: grid;
            font-size: 24px;
            margin-top: 8px;
            margin-bottom: 5px;
          }
          .header-title{
            display: grid;
            position: relative;
            text-align: center;
            align-items: center;
            width: 150px;
            h2{
              margin: 0;
              font-size: 20px;
            }
          }
        }
      }
    }
    .footer-title{
      line-height: 2!important;
      font-weight: 900;
      opacity: 0.6;
      font-size: 13px;
    }
    .table-pagination{
      font-size: 10px!important;
    }
  }
  @media (min-width: 391px) and (max-width: 480px) {
    .sheet{
      width: 283px!important;
      .table-inputs-block{
        display: none;
      }
      .scroll-block{
        display: block;
        max-width: 283px!important;
        width: 100%;
        overflow: hidden;
        overflow-x: auto;
      }
      .active-cells{
        td{
          min-width: 150px!important;
        }
      }
      .table-header {
        .header-cell {
          display: grid;
          grid-template-columns: 50% 50%;
          .header-buttons {
            display: grid;
            grid-template-columns: repeat(1, 40px);
            justify-content: right;
            align-items: center;
            margin-top: 5px;
            button{
              width: 76%;
              height: 90%;
              position: relative;
              i{
                position: absolute;
                top: 1px;
                left: 0px;
                font-size: 11px;
              }
            }
          }
          .dropdown-wrapper {
            display: grid;
            font-size: 24px;
            margin-top: 8px;
            align-items: flex-end;
            margin-bottom: 5px;
          }
          .header-title{
            display: grid;
            position: relative;
            text-align: center;
            align-items: center;
            width: 150px;
            h2{
              margin: 0;
              font-size: 16px;
            }
          }
        }
      }
    }
    .footer-wrapper{
      display: grid;
      justify-content: center;
    }
    .footer-title{
      font-weight: 900;
      opacity: 0.6;
      font-size: 12px;
      display: block;
      margin: auto;
      text-align: center;
    }
    .table-pagination{
      font-size: 10px!important;
    }
  }
  
  @media (max-width: 390px) {
    .sheet{
      width: 250px!important;
      .table-inputs-block{
        display: none;
      }
      .scroll-block{
        display: block;
        max-width: 250px !important;
        width: 100%;
        overflow: hidden;
        overflow-x: auto;
      }
      .active-cells{
        td{
          min-width: 120px!important;
        }
      }
      .table-header {
        .header-cell {
          display: grid;
          grid-template-columns: 50% 50%;
          .header-buttons {
            display: grid;
            grid-template-columns: repeat(1, 40px);
            justify-content: right;
            align-items: center;
            margin-top: 5px;
            button{
              width: 76%;
              height: 90%;
              position: relative;
              i{
                position: absolute;
                top: 1px;
                left: 0px;
                font-size: 11px;
              }
            }
          }
          .dropdown-wrapper {
            display: grid;
            font-size: 24px;
            margin-top: 8px;
            align-items: flex-end;
            margin-bottom: 5px;
          }
          .header-title{
            display: grid;
            position: relative;
            text-align: center;
            align-items: center;
            width: 150px;
            h2{
              margin: 0;
              font-size: 16px;
            }
          }
        }
      }
    }
    .footer-wrapper{
      display: grid;
      justify-content: center;
    }
    .footer-title{
      font-weight: 900;
      opacity: 0.6;
      font-size: 9px;
      text-align: center;
    }
    .table-pagination{
      font-size: 9px!important;
    }
  }

  @media (min-width: 768px) and (max-width: 800px) {

    .sheet {
      zoom: 80%;
    }
  }