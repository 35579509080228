@import './utils.scss';

html {
  body {
    overflow:hidden;
    height: 100%;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    
    div#root{
      height: 100%;
      .main-wrapper{
        height: 100%;
      }
    }
        * {
            &:focus {outline: none!important;}
        }
    }

    b {
        font-weight: 700;
    }

    strong {
        font-weight: 500;
    }

    button {
        cursor: pointer;
    }
}

.flex-stretch {flex-grow: 1;}

input {
    &:focus {
        outline: none;
    }
}


.wrapper {
    overflow: hidden;
}
