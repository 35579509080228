.pushable:not(body) {
  -webkit-transform: none;
  transform: none;
}

.sidebar-main{
  height: calc(100% - 45px);
  margin: 0;

  .pusher-content{
    width: calc(100% - 150px) !important;

    .segment-wrapper {
      overflow-y: auto;
      overflow-x: hidden;
      height: calc(100vh - 45px);
    }
  }
  .pusher-content-compressed{
    width: calc(100% - 60px) !important;
  }

  .sidebar-menu-compressed{
    top: 45px;
    z-index: 10;
    height: calc(100% - 45px) !important;
    width: 60px !important;
  }

  .sidebar-menu-full-width{
    width: 150px !important;
  }

  .sidebar-menu{
    top: 45px;
    z-index: 10;
    height: calc(100% - 45px) !important;
    transition:0.5s ease all;
    font-size: 13px;
    background: linear-gradient(to right,#3a3633 93%,#2a2725 100%) !important;
    font-family: "Open Sans",Arial,Helvetica,Sans-Serif;
    line-height: 1.42857143;


    .item{
       line-height: normal !important;
       font-size: 14px !important;
       padding: 10px 10px 10px 11px !important;
       color: #c0bbb7 !important;
       font-weight: 400 !important;
       text-decoration: none !important;
       position: relative !important;

    }

    .item:focus {
    	color: #c9c9c9 !important;
    }

    .item:hover {
    	color: #fff !important;
    	text-decoration: none
    }

    .item.active {
        color: #fff !important;
    }

    .item.inactive,
    .item.inactive:hover {
    	opacity: .5;
    	cursor: not-allowed;
    	color: #c0bbb7
    }


     .compress-button {
     	border-bottom: 1px solid #302F2F !important;
     	background: #454545 !important;
     	color: #A8A8A8 !important;
     	text-align: center;
     	padding: 0 !important;
     	width: 36px;
     	height: 28px;
     	display: block;
     	position: absolute;
     	right: 0;
     	border-radius: 5px 0 0 5px;
     	cursor: pointer;
     	margin-top: 7px;
     	font-size: 19px;
     	transition: all .1s linear 0s
     }

     .compress-button:hover {
     	background: #646464 !important;
     	color: #E4E4E4 !important;
     	right: 0;
     	width: 40px;
     	padding-right: 5px
     }

    .dashboard-icon{
      display: inline-block;
      width: 1.28571429em;
      margin-right: 10px;
    }
    .icon-name{
      display: inline-block;
    }

    .icon-name-compressed{
      display: none;
    }

    .sidebar-compressed{
      display: grid;

      .dashboard-icon-compressed{
        display: grid;
        justify-content: center;
        margin: auto;
      }
    }

    .compress-button{
      float: right;
      margin: 0;
      border: none;
      border-radius: 0;
    }
  }
}


@media (min-width: 320px) and (max-width: 375px) {
  .sidebar-main{
    .pusher-content{
      display: grid;
      width: calc(100% - 60px) !important;
      height: 100% !important;

      .segment-wrapper {
        overflow-x: hidden !important;
        overflow-y: auto !important;
        height: 95% !important;
      }

    }
    .sidebar-menu{
      width: 60px !important;
      .sidebar-mobile{
        display: grid;

        .dashboard-icon-mobile{
          display: grid;
          justify-content: center;
          margin: auto;
        }
      }

      .icon-name{
        display: none;
      }
      .compress-button{
        display: none;
      }
    }
  }
}

@media (min-width: 376px) and (max-width: 420px) {
  .sidebar-main{
    .pusher-content{
      display: grid;
      width: calc(100% - 60px) !important;
      height: 100% !important;

      .segment-wrapper {
        overflow-x: hidden !important;
        overflow-y: auto !important;
        height: 95% !important;
      }

    }
    .sidebar-menu{
      width: 60px !important;
      .sidebar-mobile{
        display: grid;

        .dashboard-icon-mobile{
          display: grid;
          justify-content: center;
          margin: auto;
        }
      }

      .icon-name{
        display: none;
      }
      .compress-button{
        display: none;
      }
    }
  }
}
