.faq {
    text-align: center;
}

.faq-title {
    font-family: "Open Sans",Arial,Helvetica,Sans-Serif;
}

.faq-container {
    display: inline-block;
    text-align: left;
}

.ui.styled.accordion {

    .title {
        background: #404040 !important;
        color: #fff !important;
        font-size: 17px;
        font-weight: bold;
        font-family: "Open Sans",Arial,Helvetica,Sans-Serif;
    }

    .active.title {
        background: #737373 !important;
        color: #fff !important;
    }

    .content {
        font-size: 17px;
        font-family: "Open Sans",Arial,Helvetica,Sans-Serif;
    }

    .title:hover {
        background: #737373 !important;
        color: #fff !important;
    }
}

.faq-form {
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
}

.faq-inputs {
    width: 100%;
}

.faq-submit {
    float: right;
}

@media (max-width: 800px) {
    .faq-form {
        width: 100%;
    }
}