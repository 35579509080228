.change-password-form{
  height: auto;
  margin-left: 40%;
  .form-segment{
    height: 100%;
    .input-wrapper{
      grid-template-columns: 1fr;
    }
    .logo-mobile{
      display: none;
    }
  }

  .input-fields{
    padding: 10px;
  }

  .grid{
    padding: 10px;
    grid-gap: 5px;
    max-width: 250px;
    text-align: center;
    margin: auto;
  }
  .button{
    position: relative;
    margin: 2% !important;
  }

  label {
    text-align: left;
  }
}

.validation-err > input {
  border:1px solid red !important;
}

@media (min-width: 350px) and (max-width: 767px) {
  .change-password-form{
     margin-left: 20px;
    .form-segment{
      border: none;
      box-shadow: none;
      .logo-mobile{
        margin-bottom: 1rem;
        display: block;
      }
    }
    .button{
      bottom: -30px;
    }
  }
}