.chartContainer {
    position: relative;
    text-align: center;
}

.summaryContainer {
    margin-top: 5%;
    text-align: center;
}

.summaryDetails {
    text-align: right;
    display: inline-block;
    width: 50%;
}

.summaryDetailsValue {
    text-align: left;
    display: inline-block;
    width: 50%;
}

.summaryDetailsHeading {
    text-decoration: underline;
}

.summaryInfoHeading {
    padding-bottom: 20px;
}

.summaryInfoContainer {
    float: left;
    margin-left: 7%;
    margin-top: 15%;
}

.summaryInfo {
    margin-right: 35%;
    font-size: 17px;
    padding-bottom: 5px;

}

.spinner {
    height: 20%;
    width: 15%;
    left: 45%;
    position: absolute;
    display: inline-block;
}

.spinnerImg {
    width: 100%;
    height: 100%;
}

.date-search {
    display: table-cell !important;
    vertical-align: middle;
    height: 50px;
    float: right;
    margin-right: 20px;
  }

.date-label {
    margin-right: 10px;
}

.dateFilter {
    width: 20% !important;
    min-width: 200px !important;
    display: inline-block !important;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    min-height: 2.71428571em;
    margin-right: 10px;
 }

 input[type="date"]::-webkit-calendar-picker-indicator {
    display:inline-block;
    margin-top:2%;
    width: 5px;
    height: 5px;
    margin-right: 3%;
}

input[type=date]::-webkit-inner-spin-button {
    margin-top:5%;
}

.dashboard-search {
    float: right !important;
}

.disabled {
    opacity: .45 !important;
  }
  
  .dropdownFilter {
     width: 20% !important;
     min-width: 200px !important;
     display: inline-block !important;
  }
  
  .dropdown-label {
    margin-right: 10px;
  }
  
  .dropdown-search {
    vertical-align: middle;
    height: 50px;
    text-align: center;
  }


  @media (min-width: 350px) and (max-width: 420px) {
    .chartContainer {
        vertical-align: middle;
        display: table-cell;
    }
  }

  @media (min-width: 421px) and (max-width: 800px) {
    .chartContainer {
        vertical-align: middle;
        display: table-cell;
    }
  }