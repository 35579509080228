.lineChartContainer {
   display: inline-block;
  }
  
.lineChartLegend {
    font-size: 14px;
    bottom: 60%;
    right: 40%;
    position: fixed;
    display: flex;
  }


  @media (min-width: 320px) and (max-width: 800px) {
    .lineChartContainer {
      position: unset;
      zoom: 70%;
    }
  }