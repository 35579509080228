.login-container {
    background-image: linear-gradient(to top, rgba(21, 23, 25, 1),rgba(124, 135, 146, 1));
}

.login-wrapper {
    margin: auto;
    width: 30%;
    padding: 100px 0;
}

.lock-icon {
    float: right;
    padding-top: 10%;
}

.app-container { 
  height: 100%; 
}

.app-container>div {
  height: 100%; 
}

.login-form{
    height: auto !important;
    .form-segment{
      border: none !important;
      background-color: transparent !important;
      height: 100% !important;
      box-shadow: none !important;
      .input-wrapper{
        grid-template-columns: 1fr !important;
        padding: 1em 1em !important;
  
        .grid{
          grid-gap: unset !important;
        }
      }
      .logo-mobile{
        display: none !important;
      }
    }
    .padding-none {
      padding: unset !important;
    }
    .margin-none {
      margin: unset !important;
    }
    .signin-button{
      width: 100% !important;
    }
    .header {
      border-radius: unset !important;
    }
  
    .remember-checkbox>label {
      color: #ffffff !important;
      margin-bottom: 20px !important;
    }
  
    .remember-checkbox>label:hover {
      color: #ffffff !important;
    }

    .input-field {
      width: 100%;
      margin-bottom: 20px;
    }

    .blur-button {
      filter: blur(3px);
      pointer-events: none;
    }
  
   
  }


  @media (min-width: 320px) and (max-width: 767px) {

    .login-wrapper {
      width: 70%;
      padding: 100px 0;
    }

  }